








































































































import { Component, Prop, Watch } from 'vue-property-decorator'
import { Bind, Debounce } from 'lodash-decorators'

// components
import Autocomplete from '@/components/_uikit/controls/Autocomplete.vue'
import DiscountSourceCourseTable from '@/components/tables/DiscountSourceCourseTable.vue'
import DiscountSourcePackageTable from '@/components/tables/DiscountSourcePackageTable.vue'
import DiscountSourceSubjectTable from '@/components/tables/DiscountSourceSubjectTable.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import DictionaryModule from '@/store/modules/dictionary'
import {
  GroupType,
  IDiscountUnits,
  NameValueResource, SaleCourseUnitResource, SalePackageUnitResource,
  SaleUnitType,
  UnitsRequest,
} from '@/store/types'
import ManagerDiscountModule from '@/store/modules/manager/discounts'

@Component({
  components: {
    Autocomplete,
    DiscountSourceCourseTable,
    DiscountSourcePackageTable,
    DiscountSourceSubjectTable,
    Select,
    TextInput,
  },
})
export default class DiscountSourcesModal extends NotifyMixin {
  @Prop({ required: true })
  private visible!: boolean

  @Prop({ required: true })
  private sources!: Array<NameValueResource|SaleCourseUnitResource|SalePackageUnitResource>

  private get types() {
    return DictionaryModule.saleUnitType
  }

  private get subjects () {
    return DictionaryModule.subjects
  }

  private get groupTypes () {
    return DictionaryModule.groupTypes.filter(group => group.value !== GroupType.LITE)
  }

  private get positionSources() {
    return ManagerDiscountModule.discountPositionsSources
  }

  private courses: NameValueResource[] = []

  private filter: UnitsRequest = {
    type: SaleUnitType.COURSES,
  }

  private scrollOptions = {
    scrollPanel: {
      scrollingX: false,
    },
  }

  private selectedPositions: Array<NameValueResource|SaleCourseUnitResource|SalePackageUnitResource> = []
  private isLoading = false

  private handleFilter(field: IDiscountUnits, value: never) {
    if (field === 'subjectIds') {
      this.filter.courseIds = undefined
      this.fetchCoursesSelect()
    }
    if (field === 'type') {
      this.filter = {
        [field]: value,
      }
      this.$emit('changeType', value)
    } else {
      this.filter = {
        ...this.filter,
        [field]: value,
      }
    }
    this.isLoading = true
    this.fetchDiscountPositionsSources()
  }

  private handleAdd() {
    if (!this.selectedPositions.length) {
      this.notifyError('Выберите хотя бы одну позицию.')
    } else {
      this.$emit('addSources', this.selectedPositions)
      this.handleClose()
    }
  }

  private selectAllSources() {
    this.selectedPositions = [...this.positionSources]
    this.$bus.$emit('selectAllSources', this.selectedPositions)
  }

  private deleteAllSources() {
    this.selectedPositions = []
    this.$bus.$emit('deleteAllSources', this.selectedPositions)
  }

  private handleClose () {
    this.$emit('update:visible', false)
    this.$emit('close', false)
  }

  private handleInput(value: Array<NameValueResource|SaleCourseUnitResource|SalePackageUnitResource>) {
    this.selectedPositions = [...value]
  }

  private fetchCoursesSelect() {
    ManagerDiscountModule.fetchCoursesSelect({
      subjectIds: this.filter.subjectIds ?? undefined,
    })
      .then(response => this.courses = response)
  }

  @Bind
  @Debounce(500)
  private fetchDiscountPositionsSources() {
    this.$bus.$emit('resetPage')
    ManagerDiscountModule.fetchDiscountPositionsSources(this.filter)
      .finally(() => this.isLoading = false)
  }

  @Watch('visible')
  private watchVisible(value: boolean) {
    if (value) {
      if (!this.positionSources.length) {
        this.fetchDiscountPositionsSources()
      }
      if (!this.courses.length) {
        this.fetchCoursesSelect()
      }
      this.selectedPositions = [...this.sources]
    }
  }
}
