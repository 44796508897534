var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VDataTable',{attrs:{"headers":_vm.headers,"items":_vm.items,"footer-props":_vm.footerOptions,"options":_vm.paginator,"show-select":"","hide-default-footer":"","checkbox-color":"primary"},on:{"update:options":function($event){_vm.paginator=$event},"update:items-per-page":function($event){_vm.paginator.page = 1},"input":function($event){return _vm.$emit('input', $event)}},scopedSlots:_vm._u([{key:"item.subject",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-start"},[_vm._v(_vm._s(item.subject.name))])]}},{key:"item.group",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"group-types"},[_c('Tag',{attrs:{"type":"bright","groupType":item.groupType.value}},[_vm._v(_vm._s(item.groupType.name))])],1)]}},{key:"item.package",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-body-1 teal--text text--darken-4"},[_c('span',{directives:[{name:"date",rawName:"v-date",value:({ date: item.months[0].name, mask: 'LLLL' }),expression:"{ date: item.months[0].name, mask: 'LLLL' }"}]}),(item.months.length > 1)?[_vm._v(_vm._s(' - ')),_c('span',{directives:[{name:"date",rawName:"v-date",value:({ date: item.months[item.months.length - 1].name, mask: 'LLLL' }),expression:"{ date: item.months[item.months.length - 1].name, mask: 'LLLL' }"}]})]:_vm._e()],2)]}},{key:"footer",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('TableFooter',{attrs:{"props":props,"filter":_vm.paginator},on:{"update:filter":function($event){_vm.paginator=$event}}})]}},{key:"no-data",fn:function(){return [_c('NoDataFound',{attrs:{"skin":"grey"}})]},proxy:true}]),model:{value:(_vm.innerSelected),callback:function ($$v) {_vm.innerSelected=$$v},expression:"innerSelected"}})}
var staticRenderFns = []

export { render, staticRenderFns }