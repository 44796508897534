









































































































import { Component, Prop, Watch } from 'vue-property-decorator'
import { Bind, Debounce } from 'lodash-decorators'

// components
import Autocomplete from '@/components/_uikit/controls/Autocomplete.vue'
import DiscountTargetCourseTable from '@/components/tables/DiscountTargetCourseTable.vue'
import DiscountTargetPackageTable from '@/components/tables/DiscountTargetPackageTable.vue'
import DiscountTargetSubjectTable from '@/components/tables/DiscountTargetSubjectTable.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import Select from '@/components/_uikit/controls/Select.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import {
  GroupType, IDiscountUnits,
  NameValueResource,
  SaleCourseUnitResource,
  SalePackageUnitResource, SaleUnitType, UnitsRequest,
} from '@/store/types'
import DictionaryModule from '@/store/modules/dictionary'
import ManagerDiscountModule from '@/store/modules/manager/discounts'

@Component({
  components: {
    Autocomplete,
    DiscountTargetCourseTable,
    DiscountTargetPackageTable,
    DiscountTargetSubjectTable,
    Select,
    TextInput,
  },
})
export default class DiscountTargetsModal extends NotifyMixin {
  @Prop({ required: true })
  private visible!: boolean

  @Prop({ required: true })
  private targets!: Array<NameValueResource|SaleCourseUnitResource|SalePackageUnitResource>

  private get types() {
    return DictionaryModule.saleUnitType
  }

  private get subjects () {
    return DictionaryModule.subjects
  }

  private get groupTypes () {
    return DictionaryModule.groupTypes.filter(group => group.value !== GroupType.LITE)
  }

  private get positionTargets() {
    return ManagerDiscountModule.discountPositionsTargets
  }

  private courses: NameValueResource[] = []

  private filter: UnitsRequest = {
    type: SaleUnitType.COURSES,
  }

  private scrollOptions = {
    scrollPanel: {
      scrollingX: false,
    },
  }

  private selectedPositions: Array<NameValueResource|SaleCourseUnitResource|SalePackageUnitResource> = []
  private isLoading = false

  private handleFilter(field: IDiscountUnits, value: never) {
    if (field === 'type') {
      this.filter = {
        [field]: value,
      }
      this.$emit('changeType', value)
    } else {
      this.filter = {
        ...this.filter,
        [field]: value,
      }
    }
    this.isLoading = true
    this.fetchDiscountPositionsTargets()
  }

  private handleAdd() {
    if (!this.selectedPositions.length) {
      this.notifyError('Выберите хотя бы одну позицию.')
    } else {
      this.$emit('addTargets', this.selectedPositions)
      this.handleClose()
    }
  }

  private selectAllTargets() {
    this.selectedPositions = [...this.positionTargets]
    this.$bus.$emit('selectAllTargets', this.selectedPositions)
  }

  private deleteAllTargets() {
    this.selectedPositions = []
    this.$bus.$emit('deleteAllTargets', this.selectedPositions)
  }

  private handleClose () {
    this.$emit('update:visible', false)
    this.$emit('close', false)
  }

  private handleInput(value: Array<NameValueResource|SaleCourseUnitResource|SalePackageUnitResource>) {
    this.selectedPositions = [...value]
  }

  private fetchCoursesSelect() {
    ManagerDiscountModule.fetchCoursesSelect()
      .then(response => this.courses = response)
  }

  @Bind
  @Debounce(500)
  private fetchDiscountPositionsTargets() {
    this.$bus.$emit('resetPage')
    ManagerDiscountModule.fetchDiscountPositionsTargets(this.filter)
      .finally(() => this.isLoading = false)
  }

  @Watch('visible')
  private watchVisible(value: boolean) {
    if (value) {
      if (!this.positionTargets.length) {
        this.fetchDiscountPositionsTargets()
      }
      if (!this.courses.length) {
        this.fetchCoursesSelect()
      }
      this.selectedPositions = [...this.targets]
    }
  }
}
