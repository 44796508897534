












































import { Component, Prop, Vue } from 'vue-property-decorator'

// components
import ButtonTextIcon from '@/components/_uikit/buttons/ButtonTextIcon.vue'
import DiscountPositionCard from '@/components/cards/DiscountPositionCard.vue'
import DiscountSourcesModal from '@/components/modals/discounts/DiscountSourcesModal.vue'
// store
import DictionaryModule from '@/store/modules/dictionary'
import { NameValueResource, SaleCourseUnitResource, SalePackageUnitResource, SaleStoreData } from '@/store/types'

@Component({
  components: {
    ButtonTextIcon,
    DiscountPositionCard,
    DiscountSourcesModal,
  },
})
export default class DiscountsSourceForm extends Vue {
  @Prop({ required: true })
  private form!: SaleStoreData

  @Prop({ required: true })
  private sources!: Array<NameValueResource|SaleCourseUnitResource|SalePackageUnitResource>

  @Prop({ default: false })
  private readonly!: boolean

  private get saleTypes() {
    return DictionaryModule.saleUnitType
  }

  private get saleTypesValue() {
    const obj: Record<string | number, string> = {}
    this.saleTypes.forEach(type => obj[type.value] = type.name)
    return obj
  }

  private showDiscountSourcesModal = false
}
