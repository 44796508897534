






















import { Component, Prop, Vue } from 'vue-property-decorator'

// components
import TableFooter from '@/components/_uikit/TableFooter.vue'
// store
import { NameValueResource } from '@/store/types'
import { GET_DEFAULT_TABLE_FILTER } from '@/store'
//utils
import { tableFooterOptions } from '@/utils/constants'

@Component({
  components: {
    TableFooter,
  },
})
export default class DiscountSourceSubjectTable extends Vue {
  @Prop({ required: true })
  private items!: NameValueResource[]

  @Prop({ required: true })
  private selected!: NameValueResource[]

  private get headers () {
    return [
      { sortable: false, cellClass: 'cell-icon cell-icon_first', text: '', value: 'data-table-select' },
      { sortable: false, text: 'Предмет', value: 'name' },
      { sortable: false, text: 'Название курса', value: 'title' },
    ]
  }

  private paginator = {
    ...GET_DEFAULT_TABLE_FILTER(),
    itemsPerPage: 10,
  }

  private footerOptions = tableFooterOptions
  private innerSelected = this.selected

  private mounted() {
    this.innerSelected = [...this.selected]
    this.$bus.$on('resetPage', this.handleResetPage)
    this.$bus.$on('selectAllSources', this.handleSelectAllSources)
    this.$bus.$on('deleteAllSources', this.handleDeleteAllSources)
  }

  private destroyed() {
    this.$bus.$off('resetPage', this.handleResetPage as any)
    this.$bus.$off('selectAllSources', this.handleSelectAllSources as any)
    this.$bus.$off('deleteAllSources', this.handleDeleteAllSources as any)
  }

  private handleSelectAllSources(sources: NameValueResource[]) {
    this.innerSelected = [...sources]
  }

  private handleDeleteAllSources() {
    this.innerSelected = []
  }

  private handleResetPage() {
    this.paginator.page = 1
  }
}
