




















import { Component, Prop, Vue } from 'vue-property-decorator'

// components
import Tag from '@/components/_uikit/Tag.vue'
// store
import {
  NameValueResource,
  SaleCourseUnitResource,
  SalePackageUnitResource,
  SaleUnitType,
} from '@/store/types'

type IDiscountPosition = {
  endMonth?: string,
  groups?: NameValueResource[],
  id: number,
  price?: number,
  startMonth?: string,
  title: string,
}

@Component({
  components: {
    Tag,
  },
})
export default class DiscountPositionCard extends Vue {
  @Prop({ required: true })
  private type!: SaleUnitType

  @Prop({ required: true })
  private position!: NameValueResource | SaleCourseUnitResource | SalePackageUnitResource

  @Prop({ default: false })
  private readonly !: SaleUnitType

  private get innerPosition(): IDiscountPosition {
    const groups: NameValueResource[] = this.type === SaleUnitType.COURSES ?
      (this.position as SaleCourseUnitResource).groups.map(group => group.type) :
      this.type === SaleUnitType.PACKAGES ?
        [(this.position as SalePackageUnitResource).groupType] : []
    const endMonth: string | undefined = this.type === SaleUnitType.SUBJECTS ? undefined :
      this.type === SaleUnitType.COURSES ? (this.position as SaleCourseUnitResource)?.educationEndAt :
        this.type === SaleUnitType.PACKAGES && (this.position as SalePackageUnitResource).months.length ? (this.position as SalePackageUnitResource).months[(this.position as SalePackageUnitResource).months.length - 1].name : undefined
    const startMonth: string | undefined = this.type === SaleUnitType.SUBJECTS ? undefined :
      this.type === SaleUnitType.COURSES ? (this.position as SaleCourseUnitResource)?.educationStartAt :
        this.type === SaleUnitType.PACKAGES && (this.position as SalePackageUnitResource).months.length ? (this.position as SalePackageUnitResource).months[0].name : undefined
    return {
      endMonth,
      groups: this.type !== SaleUnitType.SUBJECTS ? groups : undefined,
      id: this.type === SaleUnitType.SUBJECTS ? +(this.position as NameValueResource).value : (this.position as SaleCourseUnitResource | SalePackageUnitResource).id,
      price: this.type === SaleUnitType.PACKAGES ? (this.position as SalePackageUnitResource).price : undefined,
      startMonth,
      title: this.type === SaleUnitType.SUBJECTS ? (this.position as NameValueResource).name : (this.position as SaleCourseUnitResource | SalePackageUnitResource).title,
    }
  }
}
