























































































































































import { Bind, Debounce } from 'lodash-decorators'
import { Component, Prop, Ref } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { DateTime } from 'luxon'

// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// components
import Autocomplete from '@/components/_uikit/controls/Autocomplete.vue'
import ButtonTextIcon from '@/components/_uikit/buttons/ButtonTextIcon.vue'
import Confirmation from '@/components/modals/Confirmation.vue'
import DateInput from '@/components/_uikit/controls/DateInput.vue'
import DiscountsSourceForm from '@/components/forms/discounts/DiscountsSourceForm.vue'
import DiscountsTargetForm from '@/components/forms/discounts/DiscountsTargetForm.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import SwitchInput from '@/components/_uikit/controls/SwitchInput.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
// store
import DictionaryModule from '@/store/modules/dictionary'
// types
import { NameValueResource, SaleCourseUnitResource, SalePackageUnitResource, SalePriceType, SaleResource, SaleStatus, SaleStoreData, SaleUnitType } from '@/store/types'
// utils
import { parseDateToMilliseconds } from '@/utils/functions'

@Component({
  components: {
    Autocomplete,
    ButtonTextIcon,
    Confirmation,
    DateInput,
    DiscountsSourceForm,
    DiscountsTargetForm,
    Select,
    SwitchInput,
    TextInput,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class DiscountForm extends NotifyMixin {
  @Ref() confirm!: Confirmation

  @Prop({ default: null })
  private saleData!: SaleResource

  private get readonly() {
    return this.saleData && this.saleData.status !== 'draw' || false
  }

  // Дата окончания должна блокироваться, когда статус скидки - опубликовано и сама дата уже наступила
  // то есть, если сейчас 01.05.2024 17:12, а дата окончания 01.05.2024, то дата должна блокироваться,
  // потому что она уже наступила
  private get isDisabledSaleEndAt() {
    if (!this.saleData)
      return false
    return this.saleData.status === SaleStatus.ACTIVE && Date.now() > (DateTime.fromSQL(this.saleData.salesEndAt, { zone: 'Europe/Moscow' }).startOf('day') as any).ts
  }

  private tabIndex = 0

  // Объекты, которые нужно купить
  private sourceUnits: Array<NameValueResource|SaleCourseUnitResource|SalePackageUnitResource> = []
  // Объекты, на которые действует скидка
  private targetUnits: Array<NameValueResource|SaleCourseUnitResource|SalePackageUnitResource> = []

  private today = DateTime.now().toSQLDate()

  private form: SaleStoreData = {
    bannerText: '',
    handleSalesSourceAt: false,
    isRequiredWhenBuy: false,
    priceType: SalePriceType.SUM,
    salePrice: '' as unknown as number,
    salesEndAt: '',
    salesSourceAt: '',
    salesStartAt: '',
    sourceIds: [],
    sourceType: SaleUnitType.COURSES,
    targetIds: [],
    targetType: SaleUnitType.COURSES,
    title: '',
  }

  private formInit: SaleStoreData = {
    bannerText: '',
    handleSalesSourceAt: false,
    isRequiredWhenBuy: false,
    priceType: SalePriceType.SUM,
    salePrice: '' as unknown as number,
    salesEndAt: '',
    salesSourceAt: '',
    salesStartAt: '',
    sourceIds: [],
    sourceType: SaleUnitType.COURSES,
    targetIds: [],
    targetType: SaleUnitType.COURSES,
    title: '',
  }

  private mounted() {
    this.$bus.$on('publishDiscount', this.saveAndPublishDiscount)
    if (this.saleData) {
      this.form = {
        bannerText: this.saleData.bannerText || '',
        salesSourceAt: this.saleData.salesSourceAt || '',
        handleSalesSourceAt: this.saleData.handleSalesSourceAt || false,
        isRequiredWhenBuy: this.saleData.isRequiredWhenBuy,
        priceType: this.saleData.priceType || SalePriceType.SUM,
        salePrice: this.saleData.salePrice,
        salesEndAt: this.saleData.salesEndAt,
        salesStartAt: this.saleData.salesStartAt,
        sourceIds: this.saleData.sources.map((item: NameValueResource|SaleCourseUnitResource|SalePackageUnitResource) => +(item as NameValueResource).value || (item as SaleCourseUnitResource|SalePackageUnitResource).id),
        sourceType: this.saleData.sourceType.value as SaleUnitType,
        targetIds: this.saleData.targets.map((item: NameValueResource|SaleCourseUnitResource|SalePackageUnitResource) => +(item as NameValueResource).value || (item as SaleCourseUnitResource|SalePackageUnitResource).id),
        targetType: this.saleData.targetType.value as SaleUnitType,
        title: this.saleData.title,
      }
      this.formInit = Object.assign({}, this.form)
      this.sourceUnits = [...this.saleData.sources]
      this.targetUnits = [...this.saleData.targets]
    }
  }

  private destroyed() {
    this.$bus.$off('publishDiscount', this.saveAndPublishDiscount as any)
  }

  private get saleUnitType() {
    return DictionaryModule.saleUnitType
  }

  private saveAndPublishDiscount() {
    const discountData = JSON.stringify(this.formInit)
    const form = JSON.stringify(this.form)

    if (discountData === form) {
      this.$emit('publish')
    } else {
      this.handleSubmit(true)
    }
  }

  @Bind
  @Debounce(300)
  private handleSubmit(publish = false) {
    if (this.form.sourceIds.length === 0) {
      this.notifyError('Выберите хотя бы одну позицию для покупки')
      return
    }

    if (this.form.targetIds.length === 0) {
      this.notifyError('Выберите хотя бы одну позицию для скидки')
      return
    }


    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          if (parseDateToMilliseconds(this.form.salesStartAt) > parseDateToMilliseconds(this.form.salesEndAt)) {
            this.notifyError('"Дата окончания" не может быть до "Даты начала"')
            return
          }

          this.$emit('submit', { form: this.form, publish })
        } else {
          this.notifyError('Проверьте введенные данные')
        }
      })
  }

  private confirmDelete () {
    this.confirm.open(
      'Удаление скидки',
      'Вы уверены, что хотите удалить скидку?',
      {
        buttonConfirmText: 'Удалить',
      },
    )
      .then(() => this.$emit('delete'))
      .catch(() => {return})
  }

  private handleRemoveCourseSource(id: number) {
    this.form.sourceIds = this.form.sourceIds.filter(item => item !== id)
    this.sourceUnits = (this.sourceUnits as SaleCourseUnitResource[]).filter((item: SaleCourseUnitResource) => item.id !== id)
  }

  private handleRemoveSubjectSource(id: number) {
    this.form.sourceIds = this.form.sourceIds.filter(item => item !== id)
    this.sourceUnits = (this.sourceUnits as NameValueResource[]).filter((item: NameValueResource) => item.value !== id)
  }

  private handleRemovePackageSource(id: number) {
    this.form.sourceIds = this.form.sourceIds.filter(item => item !== id)
    this.sourceUnits = (this.sourceUnits as SalePackageUnitResource[]).filter((item: SalePackageUnitResource) => item.id !== id)
  }

  private handleDeleteAllSources() {
    this.form.sourceIds = []
    this.sourceUnits = []
  }

  private handleAddSources(sources: Array<NameValueResource|SaleCourseUnitResource|SalePackageUnitResource>) {
    this.form.sourceIds = sources.map((item: NameValueResource|SaleCourseUnitResource|SalePackageUnitResource) => +(item as NameValueResource).value || (item as SaleCourseUnitResource|SalePackageUnitResource).id)
    this.sourceUnits = [...sources]
  }

  private handleRemoveCourseTarget(id: number) {
    this.form.targetIds = this.form.targetIds.filter(item => item !== id)
    this.targetUnits = (this.targetUnits as SaleCourseUnitResource[]).filter((item: SaleCourseUnitResource) => item.id !== id)
  }

  private handleRemoveSubjectTarget(id: number) {
    this.form.targetIds = this.form.targetIds.filter(item => item !== id)
    this.targetUnits = (this.targetUnits as NameValueResource[]).filter((item: NameValueResource) => item.value !== id)
  }

  private handleRemovePackageTarget(id: number) {
    this.form.targetIds = this.form.targetIds.filter(item => item !== id)
    this.targetUnits = (this.targetUnits as SalePackageUnitResource[]).filter((item: SalePackageUnitResource) => item.id !== id)
  }

  private handleDeleteAllTargets() {
    this.form.targetIds = []
    this.targetUnits = []
  }

  private handleAddTargets(targets: Array<NameValueResource|SaleCourseUnitResource|SalePackageUnitResource>) {
    this.form.targetIds = targets.map((item: NameValueResource|SaleCourseUnitResource|SalePackageUnitResource) => +(item as NameValueResource).value || (item as SaleCourseUnitResource|SalePackageUnitResource).id)
    this.targetUnits = [...targets]
  }
}
