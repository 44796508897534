var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VDataTable',{attrs:{"headers":_vm.headers,"items":_vm.items,"footer-props":_vm.footerOptions,"options":_vm.paginator,"show-select":"","hide-default-footer":"","checkbox-color":"primary"},on:{"update:options":function($event){_vm.paginator=$event},"update:items-per-page":function($event){_vm.paginator.page = 1},"input":function($event){return _vm.$emit('input', $event)}},scopedSlots:_vm._u([{key:"item.subject",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-start"},[_vm._v(_vm._s(item.subject.name))])]}},{key:"item.package",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-body-1 grey--text"},[_vm._v("Все пакеты")])]}},{key:"item.groups",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"group-types"},_vm._l((item.groups),function(type){return _c('Tag',{key:type.type.value,attrs:{"type":"bright","groupType":type.type.value}},[_vm._v(_vm._s(type.type.name))])}),1)]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('div',{directives:[{name:"date-range",rawName:"v-date-range",value:({ end: item.educationEndAt, start: item.educationStartAt }),expression:"{ end: item.educationEndAt, start: item.educationStartAt }"}],staticClass:"text-start"})]}},{key:"footer",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('TableFooter',{attrs:{"props":props,"filter":_vm.paginator},on:{"update:filter":function($event){_vm.paginator=$event}}})]}},{key:"no-data",fn:function(){return [_c('NoDataFound',{attrs:{"skin":"grey"}})]},proxy:true}]),model:{value:(_vm.innerSelected),callback:function ($$v) {_vm.innerSelected=$$v},expression:"innerSelected"}})}
var staticRenderFns = []

export { render, staticRenderFns }