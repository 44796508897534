import { render, staticRenderFns } from "./DiscountForm.vue?vue&type=template&id=2061cea8&scoped=true&lang=pug&"
import script from "./DiscountForm.vue?vue&type=script&lang=ts&"
export * from "./DiscountForm.vue?vue&type=script&lang=ts&"
import style0 from "./DiscountForm.vue?vue&type=style&index=0&id=2061cea8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2061cea8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VBtn,VCardText,VCheckbox,VDivider,VSwitch,VTab,VTabItem,VTabs,VTabsItems})
